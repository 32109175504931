<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">Clientes</h3>
                  <p class="mt-2 mb-n1 ml-3 text-muted">
                    {{ totalRows | numberFilter }}
                  </p>
                </div>
              </b-col>
              <b-col>
                <b-button-toolbar
                  aria-label="Barra de Ferramentas"
                  class="d-flex flex-row-reverse"
                >
                  <b-button-group class="mx-1">
                    <b-button variant="primary" @click="edit(null)">
                      <i class="mdi mdi-plus"></i>
                      Novo Cliente</b-button
                    >
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-card-title>

          <b-table
            ref="table"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            responsive
            :busy="loadingTable"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #cell(nome)="row">
              <span class="text-wrap">
                {{ row.item.nome }}
              </span>
            </template>
            <template #cell(projetos_count)="row">
              <b-badge variant="primary">{{ row.item.projetos_count }}</b-badge>
            </template>
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                title="Editar"
                @click="edit(row.item)"
              >
                <i class="mdi mdi-pencil"></i>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="mr-1"
                title="Remover"
                @click="remove(row.item)"
              >
                <i class="mdi mdi-delete"></i>
              </b-button>
            </template>
          </b-table>

          <b-row class="justify-content-md-center">
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="md"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <b-sidebar
      id="sidebar-edit"
      no-title
      bg-variant="dark"
      text-variant="light"
      width="500px"
      shadow
      right
      backdrop
      backdrop-variant="dark"
    >
      <div class="px-3 py-2">
        <h4 class="card-title">Cliente</h4>
        <!-- <p class="card-description">Basic form layout</p> -->
        <form class="forms-sample" @submit.stop.prevent="saveOrUpdate">
          <b-form-group label="Nome" label-for="nomeInput">
            <b-input-group>
              <b-form-input
                id="nomeInput"
                type="text"
                placeholder="Nome"
                v-model="$v.cliente.nome.$model"
                :state="$v.cliente.nome.$dirty ? !$v.cliente.nome.$error : null"
                aria-describedby="nome-live-feedback"
                maxlength="255"
              ></b-form-input>
              <b-form-invalid-feedback id="nome-live-feedback">
                <template v-if="!$v.cliente.nome.required"
                  >Preenchimento obrigatório</template
                >
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <div class="d-flex">
            <b-button
              type="submit"
              variant="success"
              class="mr-2"
              :disabled="loading"
            >
              <template v-if="loading">
                <b-spinner small type="grow"></b-spinner>
                Processando...
              </template>
              <template v-else> Enviar </template>
            </b-button>
          </div>
        </form>
      </div>
    </b-sidebar>
  </section>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import ClienteService from "../../services/cliente.service";


export default {
  name: "Clientes",
  data() {
    return {
      fields: [
        { key: "nome", sortable: true },
        { key: "projetos_count", label: "Projetos", sortable: false },
        { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "nome",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      cliente: {
        id: null,
        nome: "",
      },
      loading: false,
    };
  },
  methods: {
    search(ctx) {
      let params = {
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = ClienteService.getAll(params);

      return promise
        .then((response) => {
          this.loadingTable = false;
          const items = response.data.data;

          // console.log(items);


          this.totalRows = response.data.total;
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },
    saveOrUpdate() {
      this.loading = true;
      this.$v.cliente.$touch();
      if (this.$v.cliente.$anyError) {
        this.loading = false;
        return;
      }

      const promise = ClienteService.saveOrUpdate(this.cliente);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.cliente = {
            id: null,
            nome: "",
          };
          this.$v.cliente.$reset();
          this.$refs.table.refresh();
          this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    edit(item) {
      if (item) {
        this.cliente = {
          id: item.id,
          nome: item.nome,
        };
      } else {
        this.cliente = {
          id: null,
          nome: "",
        };
      }
      this.$v.cliente.$reset();
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir este registro?", {
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "Sim",
          cancelVariant: "outline-secondary",
          cancelTitle: "Cancelar",
          footerClass: "p-2 border-top-0",
          centered: true,
          footerBgVariant: "dark",
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const promise = ClienteService.delete(item.id);
            return promise
              .then((response) => {
                this.$bvToast.toast(response.data.message, {
                  title: "Sucesso!",
                  autoHideDelay: 1000,
                  toaster: "b-toaster-bottom-left",
                  variant: "success",
                });
                this.$refs.table.refresh();
              })
              .catch(() => {})
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
  },
  validations: {
    cliente: {
      nome: {
        required,
      },
    },
  },
};
</script>
